import React, { ReactNode } from 'react';
import ContactForm from '@/components/contact-section/contact-form';
import { Button, Container, PageSection } from '@/components/_root';
import * as styles from './style.module.css';
import useWindowDimensions from '@/utils/hooks';
import { ButtonWrapper, ForUsBody, WithUsBody } from './styled';
import SectionTitle from '@/components/section-title';

interface Props {
  workWithUsData: {
    title: { solid?: string; outlined?: string };
    body: ReactNode;
  };
  workForUsData: {
    title: { solid?: string; outlined?: string };
    body: ReactNode;
  };
}

const WorkWithUs = ({ workWithUsData, workForUsData }: Props): JSX.Element => {
  const { width } = useWindowDimensions();
  return (
    <PageSection paddingAmount="small">
      <Container>
        <div className={styles.grid}>
          <div className={`mb-10 md:mb-0 ${styles.workWithUs}`}>
            <SectionTitle title={{ ...workWithUsData.title, isOutlinedFirst: true }} isInline />
            <WithUsBody workWithUsBody={workWithUsData.body} />
          </div>
          <div className={`mt-10 md:mt-24  ${styles.workForUs}`}>
            <SectionTitle
              title={{ ...workForUsData.title, isOutlinedFirst: true }}
              isInline
              size="h3"
            />
            <ForUsBody workForUsBody={workForUsData.body} />
            <ButtonWrapper>
              <Button
                mode="light"
                shape="round"
                size={width > 768 ? 'large' : 'medium'}
                type="secondary"
                href="/careers"
              >
                See all careers
              </Button>
            </ButtonWrapper>
          </div>
          <div className={`${styles.formArea} mx-auto w-full md:pl-16 lg:container`}>
            <ContactForm showFullForm={true} isDark={false} isMobileHidden={false} />
          </div>
        </div>
      </Container>
    </PageSection>
  );
};

export default WorkWithUs;
