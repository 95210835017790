import React, { ReactNode } from 'react';
import { IAddress } from '@/utils/interface';
import { Button, HeaderText } from '@/components/_root';
import * as styles from './style.module.css';
import BlockContent from '@/components/block-content';
import { StaticImage } from 'gatsby-plugin-image';
import Link from 'gatsby-link';
interface Props {
  children?: ReactNode;
}

interface CarouselAddressProps {
  address: IAddress;
}

interface Team {
  address: string;
  address_short_text: string;
  team_member: string;
  direction: string;
  slug?: { current: string };
}

interface CarouselTeamProps {
  index?: number;
  team: Team;
}

export const FlexWrapper = ({ children }: Props): JSX.Element => {
  return <div className="flex flex-col md:flex-row md:space-x-8 items-center">{children}</div>;
};

export const HeaderWrapper = ({ children }: Props): JSX.Element => {
  return <div className="w-full flex-shrink-0 pb-10 md:pb-0">{children}</div>;
};

export const BodyWrapper = ({ children }: Props): JSX.Element => {
  if (typeof children !== 'string')
    return (
      <div className="pt-6 text-white flex flex-col space-y-4 w-4/5">
        <BlockContent blocks={children} />
      </div>
    );
  return <div className="pt-6 text-white flex flex-col space-y-4 w-4/5">{children}</div>;
};

export const CarouselWrapper = ({ address }: CarouselAddressProps): JSX.Element => {
  const outerWrapper =
    'bg-no-repeat w-80 bg-top rounded-md px-10 py-40 md:py-48 relative overflow-hidden';
  return (
    <div className={outerWrapper}>
      <StaticImage
        src="../../../assets/img/mobile-menu-bg.png"
        alt=""
        className="w-full z-0 h-full top-0 left-0"
        style={{ position: 'absolute' }}
        placeholder="blurred"
      />
      <CarouselItem address={address} />
    </div>
  );
};

export const CarouselItem = ({ address }: CarouselAddressProps): JSX.Element => {
  const paragraphClass = 'text-white py-4 w-10/12 cursor-pointer';
  return (
    <>
      <BackgroundText address={address} />
      <div className="absolute z-10 top-20 lg:top-32">
        <HeaderText size="h3" mode="dark" className="cursor-pointer">
          {address.city}
        </HeaderText>
        <p className={paragraphClass}>{address.address.split('+').join('\n+')}</p>
        <p className="text-white pb-4">{address.mobile}</p>
        <div className="w-36 lg:w-52 pb-6 text-center">
          <Button href={address.direction} type="secondary" mode="dark" block={true}>
            Get Directions
          </Button>
        </div>
      </div>
    </>
  );
};

export const CarouselGradientWrapper = ({ index, team }: CarouselTeamProps): JSX.Element => {
  const outerWrapper =
    'bg-no-repeat w-11/12 bg-top rounded-md md:px-10 py-40 lg:py-44 relative overflow-hidden';

  const getImage = (i: number): JSX.Element => {
    if (i % 2 === 0)
      return (
        <StaticImage
          src="../../../assets/img/mobile-menu-bg.png"
          alt=""
          className="w-full z-0 h-full top-0 left-0"
          style={{ position: 'absolute' }}
          placeholder="blurred"
        />
      );

    return (
      <StaticImage
        src="../../../assets/img/008.png"
        alt=""
        className="w-full z-0 h-full top-0 left-0"
        style={{ position: 'absolute' }}
        placeholder="blurred"
      />
    );
  };

  return (
    <div className={outerWrapper}>
      {getImage(index as number)}
      <CarouselGradientItem team={team} />
    </div>
  );
};

export const CarouselGradientItem = ({ team }: CarouselTeamProps): JSX.Element => {
  const paragraphClass = 'text-white  w-10/12 cursor-pointer';
  console.log(team)
  return (
    <Link to={`/career/${team.slug}`}>
      <BackgroundText address={team} />
      <div className="absolute z-10 flex top-1/2 bottom-1/2 left-0 right-0 mx-auto flex-col items-center justify-center text-center">
        <HeaderText size="h3" mode="dark" className="w-8/12 cursor-pointer">
          {team.team_member}
        </HeaderText>
        <p className={paragraphClass}>{team.address}</p>
      </div>
    </Link>
  );
};

export const BackgroundText = ({ address }: CarouselAddressProps): JSX.Element => {
  return (
    <div className="absolute z-0 top-32  opacity-25">
      <h1 className={styles.headerOutlineWhite}>
        {address?.city_short ?? address?.address_short_text}
      </h1>
    </div>
  );
};
