import { ISocialMedia } from '@/utils/interface';
import React from 'react';
import getGradientSteps from '@/utils/helper/getGradientSteps';
import useWindowDimensions from '@/utils/hooks';

import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '@/components/_icons/';
import { OuterWrapper, SocialMediaContainer, TextWrapper } from './styled';

const ICON_SIZES = {
  sm: '24', // '640px',
  md: '36', // '768px',
  lg: '48', // '1024px',
  xl: '56', // '1200px',
};

interface Props {
  socialUrls?: ISocialMedia;
  textContent: string;
}

const colors: string[] = getGradientSteps(['#E8006F', '#CB00FF'], 6);

const DifferentWay = ({ socialUrls, textContent }: Props): JSX.Element => {
  const { container } = useWindowDimensions();

  const socialLinks = [
    {
      icon: <FacebookIcon fillColor={colors[5]} size={ICON_SIZES[container]} />,
      link: socialUrls?.facebook,
    },
    {
      icon: <TwitterIcon fillColor={colors[4]} size={ICON_SIZES[container]} />,
      link: socialUrls?.twitter,
    },
    {
      icon: <InstagramIcon fillColor={colors[3]} size={ICON_SIZES[container]} />,
      link: socialUrls?.instagram,
    },
    {
      icon: <LinkedinIcon fillColor={colors[2]} size={ICON_SIZES[container]} />,
      link: socialUrls?.linkedin,
    },
    {
      icon: <YoutubeIcon fillColor={colors[1]} size={ICON_SIZES[container]} />,
      link: socialUrls?.youtube,
    },
    {
      icon: <TiktokIcon fillColor={colors[0]} size={ICON_SIZES[container]} />,
      link: socialUrls?.tiktok,
    },
  ];

  return (
    <OuterWrapper>
      <TextWrapper>{textContent}</TextWrapper>
      <SocialMediaContainer>
        {socialLinks.map((socialLink, index) => (
          <a
            href={socialLink.link}
            key={index}
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer"
          >
            {socialLink.icon}
          </a>
        ))}
      </SocialMediaContainer>
    </OuterWrapper>
  );
};

export default DifferentWay;
