import HoverList from '@/components/hover-list';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const OuterWrapper = ({ children }: Props): JSX.Element => (
  <div className="flex flex-col justify-center">{children}</div>
);

export const SocialMediaContainer = ({ children }: Props): JSX.Element => (
  <HoverList className="flex gap-4 md:gap-8 justify-center items-center">{children}</HoverList>
);

export const TextWrapper = ({ children }: Props): JSX.Element => (
  <div className="pt-5 md:pt-7 pb-10 md:pb-14 md:w-9/12 mx-auto">
    <p className="w-11/12  lg:w-9/12 mx-auto text-center">{children}</p>
  </div>
);
