import React, { ReactNode } from 'react';
import BlockContent from '@/components/block-content';
interface UsProps {
  workWithUsBody: ReactNode;
}

interface ForProps {
  workForUsBody: ReactNode;
}

interface Props {
  children: ReactNode;
}

export const WithUsBody = ({ workWithUsBody }: UsProps): JSX.Element => (
  <div className="pt-10 flex flex-col space-y-8">
    <BlockContent blocks={workWithUsBody} />
  </div>
);

export const ForUsBody = ({ workForUsBody }: ForProps): JSX.Element => (
  <div className="pt-10 flex flex-col space-y-8">
    <BlockContent blocks={workForUsBody} />
  </div>
);

export const ButtonWrapper = ({ children }: Props): JSX.Element => (
  <div className="w-80 mt-12">{children}</div>
);
