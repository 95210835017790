import React, { ReactNode } from 'react';
import { PageSection, Swiper, SwiperItem } from '@/components/_root';
import { IAddress } from '@/utils/interface';
import {
  BodyWrapper,
  CarouselGradientWrapper,
  CarouselWrapper,
  FlexWrapper,
  HeaderWrapper,
} from './styled';
import { useOffset } from '@/components/_hook';
import * as styles from './styled/style.module.css';
import SectionTitle from '../section-title';

interface Team {
  address: string;
  address_short_text: string;
  team_member: string;
  direction: string;
  slug?: { current: string };
}

interface DataProps {
  addresses?: IAddress[];
  teams?: Team[];
}

interface HeaderProps {
  outlined: string;
  solid: string;
  isOutlinedFirst?: boolean;
  inline?: boolean;
}

interface Props {
  title: HeaderProps;
  body?: ReactNode;
  addresses?: IAddress[];
  teams?: Team[];
}

const OurOffices = ({ title, body, addresses, teams }: Props): JSX.Element => {
  const offset = useOffset();

  return (
    <PageSection paddingAmount="small" mode="dark" className="overflow-hidden">
      <FlexWrapper>
        <div className={`md:w-6/12`} style={offset ? { paddingLeft: offset } : {}}>
          <HeaderWrapper>
            <SectionTitle title={title} mode="dark" />
            {body && <BodyWrapper>{body}</BodyWrapper>}
          </HeaderWrapper>
        </div>
        <OurOffices.Carousel addresses={addresses} teams={teams} />
      </FlexWrapper>
    </PageSection>
  );
};

OurOffices.Carousel = ({ addresses, teams }: DataProps): JSX.Element => {
  const SHOW_ITEMS = {
    sm: 1,
    md: 2,
    lg: 2,
    xl: 2,
  };
  return (
    <div className="md:w-6/12">
      <Swiper
        showBleeding
        // haveOffset
        spaceBetween={{ sm: 17, md: 20, lg: 30, xl: 40 }}
        showItems={SHOW_ITEMS}
        isLoop={false} // We don't want to loop the carousel
      >
        {addresses &&
          addresses.map((address: IAddress, index) => (
            <SwiperItem key={index} className={styles.width}>
              <CarouselWrapper address={address} />
            </SwiperItem>
          ))}
        {teams &&
          teams.map((team: Team, index) => (
            <SwiperItem key={index} className={styles.width}>
              <CarouselGradientWrapper team={team} index={index} />
            </SwiperItem>
          ))}
      </Swiper>
    </div>
  );
};

export default OurOffices;
