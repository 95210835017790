import React from 'react';
import Layout from '@/components/_layout';
import Seo from '@/components/_layout/seo';
import { graphql, PageProps } from 'gatsby';

import PageBanner from '@/components/teams-page-banner';
import WorkWithUs from '@/components/work-with-us';
import OurOffices from '@/components/our-offices';
import DifferentWay from '@/components/different-way-section';
import Faporbaji from '@/components/faporbaji';

import { getTitle } from '@/utils/helper/getTitle';

const Contact = ({ data, location, pageContext }: PageProps<any>): JSX.Element => {
  const {
    // @ts-ignore
    breadcrumb: { crumbs },
  } = pageContext;

  return (
    <Layout location={location} crumbLabel="Contact" crumbs={crumbs}>
      <Seo
        title={data?.sanityContactPageMetadata?.contact_page_metadata?.pageMetaName || 'Contact'}
        description={data?.sanityContactPageMetadata?.contact_page_metadata?.pageMetaDescription}
        pageMetadata={data?.sanityContactPageMetadata?.contact_page_metadata?.metadata}
        canonical={data?.sanityContactPageMetadata?.contact_page_metadata?.canonical}
      />
      <PageBanner
        backgroundImageUrl={data?.sanityContactBanner?.background_image}
        title={{ solid: data?.sanityContactBanner?.title }}
        isAddress={false}
      />
      <WorkWithUs
        workWithUsData={{
          title: {
            outlined: data?.sanityContactWorkWithUs?.primary_title?.title_outlined,
            solid: data?.sanityContactWorkWithUs?.primary_title?.title_solid,
          },
          body: data?.sanityContactWorkWithUs?._rawPrimaryDescription,
        }}
        workForUsData={{
          title: {
            outlined: data?.sanityContactWorkWithUs?.secondary_title?.title_outlined,
            solid: data?.sanityContactWorkWithUs?.secondary_title?.title_solid,
          },
          body: data?.sanityContactWorkWithUs?._rawSecondaryDescription,
        }}
      />
      <OurOffices
        title={getTitle(data?.sanityContactOurOffices)}
        addresses={data?.sanityContactOurOffices?.offices}
      />
      <Faporbaji
        isDark={false}
        title={{ solid: data?.sanityContactingMedia?.title }}
        headerClass="w-10/12 lg:w-6/12 mx-auto text-center"
        body={
          <DifferentWay
            textContent={data?.sanityContactingMedia?.description}
            socialUrls={data?.sanityContactingMedia?.social_media}
          />
        }
      />
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query {
    sanityContactPageMetadata {
      contact_page_metadata {
        metadata {
          metaContent
          metaName
        }
        canonical
        pageMetaDescription
        pageMetaName
      }
    }
    sanityContactBanner {
      id
      title
      background_image {
        asset {
          gatsbyImageData(width: 500, placeholder: BLURRED)
        }
      }
    }

    sanityContactWorkWithUs {
      primary_title {
        title_solid
        title_outlined
        is_outline_first
      }
      secondary_title {
        title_solid
        title_outlined
        is_outline_first
      }
      _rawPrimaryDescription
      _rawSecondaryDescription
    }

    sanityContactOurOffices {
      id
      title {
        title_solid
        title_outlined
        is_outline_first
      }
      offices {
        mobile
        direction
        city_short
        address
        city
      }
    }

    sanityContactingMedia {
      id
      social_media {
        youtube
        twitter
        tiktok
        linkedin
        facebook
        instagram
      }
      title
      description
    }
  }
`;
